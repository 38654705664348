const initialStateHutang={
    dataAllHutang:[],
    dataRincianHutang:[],
    isLoadingHutang:false,
    hutangMessage:'',
    searchDataHutang:'',
    dataPayment:'',
    pageHutang : {
        currentPage:'1',
        totalPage:'1'
    },
    dataRincianTransaksi:[],
    tempIdHutang:''
}

const hutangReducers=(state=initialStateHutang,action)=>{
    switch(action.type){
        case 'FETCH ACTION HUTANG':{
            return{
                ...state,
                isLoadingHutang:true
            }
        }
        case 'UPDATE DATA HUTANG':{
            return{
                ...state,
                isLoadingHutang:false,
                dataAllHutang:action.payload
            }
        }
        case 'UPDATE DATA RINCIAN TRANSAKSI':{
            return{
                ...state,
                isLoadingHutang:false,
                dataRincianTransaksi:action.payload
            }
        }
        case 'SET EMPTY DATA HUTANG':{
            return{
                ...state,
                isLoadingHutang:false,
                pageHutang : {
                    currentPage:'1',
                    totalPage:'1'
                },
                dataAllHutang:[]
            }
        }
        case 'UPDATE PAGE HUTANG':{
            return {
                ...state,
                pageHutang:action.payload
            }
        }
        case 'SET DATA ID HUTANG':{
            return{
                ...state,
                tempIdHutang:action.payload,
                isLoadingHutang:false
            }
        }
        case 'SET RINCIAN HUTANG':{
            return {
                ...state,
                isLoadingHutang:false,
                dataRincianHutang:action.payload
            }
        }
        case 'FAILED ACTION HUTANG':{
            return{
                ...state,
                isLoadingHutang:false,
                hutangMessage:action.payload
            }
        }
        case 'SET SEARCH TEXT HUTANG':{
            return{
                ...state,
                searchDataHutang:action.payload
            }
        }
        case 'SET EMPTY MESSAGE HUTANG':{
            return{
                ...state,
                hutangMessage:action.payload
            }
        }
        case 'SET DATA HUTANG FOR PAYMENT':{
            return{
                ...state,
                dataPayment:action.payload
            }
        }
        case 'SET SUCCESSED ACTION HUTANG':{
            return{
                ...state,
                hutangMessage:action.payload,
                isLoadingHutang:false
            }
        }
        case 'SUCCESSED DELETE HUTANG':{
            return{
                ...state,
                hutangMessage:action.payload,
                isLoadingHutang:false
            }
        }
        default:
        return state
    }
}
export default hutangReducers