import axios from "axios";
import { apiUrl } from '../..';

export const setSearchHutang = (search) => (dispatch) =>{
    dispatch({
        type:'SET SEARCH TEXT HUTANG',
        payload :search
    })
}
export const setEmptyDataHutang = () =>(dispatch)=>{
    dispatch({
        type:'SET EMPTY DATA HUTANG',
    })
}
export const getAllHutang = (search,page,token,viewhutang,perPage) =>(dispatch)=>{
    dispatch({
        type:'FETCH ACTION HUTANG'
    })
    axios.get(`${apiUrl.url}v1/suryaharapan/hutang/?search=${search}&viewhutang=${viewhutang}&page=${page}&perPage=${perPage}`,{
        headers:{
            'Authorization':`Bearer ${token}`
        }
    })
    .then(res=>{
        const ResponseAPI = res.data;
        dispatch({
            type:'UPDATE DATA HUTANG', 
            payload:ResponseAPI.data,
            fetchPayload:'Successed'
         })
         dispatch({
             type:'UPDATE PAGE HUTANG',
             payload:{
                 currentPage: ResponseAPI.currentPage,
                 totalPage: Math.ceil(ResponseAPI.total_hutang/ResponseAPI.perPage)
             }
         })
    })
    .catch(error=>{
        if(error.response){
            if(error.response.status === 500){
                dispatch({
                    type:'FAILED ACTION HUTANG',
                    payload:error.response.data.message
                })
            }else if(error.response.status === 401){
                dispatch({
                    type:'FAILED ACTION HUTANG',
                    payload:error.response.data.message
                })
            }
        }else if(error.request){
                 dispatch({
                    type:'FAILED ACTION HUTANG',
                    payload:'Disconnect from server'
            })
        }
    })
}
export const getDataRincianTransaksi = (idtransaksi,token)=>(dispatch)=>{
    const data ={
        'idtransaksi':idtransaksi
    }
    dispatch({
        type:'FETCH ACTION HUTANG'
    })
    axios.post(`${apiUrl.url}v1/suryaharapan/hutang/details`,data,{
        headers:{
            'Authorization':`Bearer ${token}`
        }
    })
    .then(res=>{
        const ResponseAPI=res.data;
        dispatch({
            type:'UPDATE DATA RINCIAN TRANSAKSI',
            payload:ResponseAPI.data
        })
    })
    .catch(error=>{
        if(error.response){
            if(error.response.status === 500){
                dispatch({
                    type:'FAILED ACTION HUTANG',
                    payload:error.response.data.message
                })
            }else if(error.response.status === 401){
                dispatch({
                    type:'FAILED ACTION HUTANG',
                    payload:error.response.data.message
                })
            }
        }else if(error.request){
                 dispatch({
                    type:'FAILED ACTION HUTANG',
                    payload:'Disconnect from server'
            })
        }
    })
}
export const getRincianHutang = (idpartner,statusHutang,page,token) =>(dispatch)=>{
    const data={
        'viewHutang':statusHutang
    }
    dispatch({
        type:'FETCH ACTION HUTANG'
    })
    axios.post(`${apiUrl.url}v1/suryaharapan/hutang/bypartner/${idpartner}?&page=${page}&perPage=50`,data,{
        headers:{
            'Authorization':`Bearer ${token}`
        }
    })
    .then(res=>{
        const ResponseAPI = res.data;
        dispatch({
            type:'SET RINCIAN HUTANG', 
            payload:ResponseAPI.data
         })
    })
    .catch(error=>{
        if(error.response){
            if(error.response.status === 500){
                dispatch({
                    type:'FAILED ACTION HUTANG',
                    payload:error.response.data.message
                })
            }else if(error.response.status === 401){
                dispatch({
                    type:'FAILED ACTION HUTANG',
                    payload:error.response.data.message
                })
            }
        }else if(error.request){
                 dispatch({
                    type:'FAILED ACTION HUTANG',
                    payload:'Disconnect from server'
            })
        }
    })
}

export const setMessageHutang = (message) => (dispatch) =>{
    dispatch({
        type:'SET EMPTY MESSAGE HUTANG',
        payload:message
    })
}
export const deleteTransaksiDetails = (idhutang,token,setOpenSnackbar,HandleClickCloseDeleteDialog)=>(dispatch)=>{
    dispatch({
        type:'FETCH ACTION HUTANG'
    })
    axios.delete(`${apiUrl.url}v1/suryaharapan/hutang/penghapusanbyidhutang/${idhutang}`,{
        headers:{
            'Authorization':`Bearer ${token}`
        }
    })
    .then(res=>{
        const ResponseAPI = res.data;
        dispatch({
            type:'SET SUCCESSED ACTION HUTANG', 
            payload:ResponseAPI.message
         })
         setOpenSnackbar(true);
         HandleClickCloseDeleteDialog();
    })
    .catch(error=>{
        if(error.response){
            if(error.response.status === 500){
                dispatch({
                    type:'FAILED ACTION HUTANG',
                    payload:error.response.data.message
                })
            }else if(error.response.status === 401){
                dispatch({
                    type:'FAILED ACTION HUTANG',
                    payload:error.response.data.message
                })
            }
        }else if(error.request){
                 dispatch({
                    type:'FAILED ACTION HUTANG',
                    payload:'Disconnect from server'
            })
        }
    })
}
export const setDataForPayment = (data,fromForm,history) =>(dispatch)=>{
    dispatch({
        type:'SET DATA HUTANG FOR PAYMENT',
        payload:data
    })
    if(fromForm==='PH'){
        history.push(`/PrintPembelian/PH/${data.idtransaksi}`);
   }else if(fromForm==='TH'){
       history.push(`/PrintTransaksi/TH/${data.idtransaksi}`);
   }
}

export const postHutang = (data,token,setOpenSnackbar,formik,handleClickBayar) => (dispatch)=>{
    dispatch({
        type:'FETCH ACTION HUTANG'
    })
    axios.post(`${apiUrl.url}v1/suryaharapan/hutang/addnew`,data,{
        headers:{
            'Authorization':`Bearer ${token}`
        }
    })
    .then(res=>{
        const ResponseAPI = res.data;
        dispatch({
            type:'SET SUCCESSED ACTION HUTANG', 
            payload:ResponseAPI.message
         })
         setOpenSnackbar(true);
         formik.handleReset();
         handleClickBayar();
    })
    .catch(error=>{
        if(error.response){
            if(error.response.status === 500){
                dispatch({
                    type:'FAILED ACTION HUTANG',
                    payload:error.response.data.message
                })
            }else if(error.response.status === 401){
                dispatch({
                    type:'FAILED ACTION HUTANG',
                    payload:error.response.data.message
                })
            }
        }else if(error.request){
                 dispatch({
                    type:'FAILED ACTION HUTANG',
                    payload:'Disconnect from server'
            })
        }
    })
}
export const postNewHutang = (data,token,setOpenSnackbar,formik) => (dispatch)=>{
    dispatch({
        type:'FETCH ACTION HUTANG'
    })
    axios.post(`${apiUrl.url}v1/suryaharapan/hutang/addnew`,data,{
        headers:{
            'Authorization':`Bearer ${token}`
        }
    })
    .then(res=>{
        const ResponseAPI = res.data;
        dispatch({
            type:'SET SUCCESSED ACTION HUTANG', 
            payload:ResponseAPI.message
         })
         setOpenSnackbar(true);
         formik.handleReset();
    })
    .catch(error=>{
        if(error.response){
            if(error.response.status === 500){
                dispatch({
                    type:'FAILED ACTION HUTANG',
                    payload:error.response.data.message
                })
            }else if(error.response.status === 401){
                dispatch({
                    type:'FAILED ACTION HUTANG',
                    payload:error.response.data.message
                })
            }
        }else if(error.request){
                 dispatch({
                    type:'FAILED ACTION HUTANG',
                    payload:'Disconnect from server'
            })
        }
    })
}
export const postHutangWithPenyesuaian = (dataPembelian,dataPenyesuian,token,setOpenSnackbar,formik,handleClickBayar) => (dispatch)=>{
    dispatch({
        type:'FETCH ACTION HUTANG'
    })
    axios.post(`${apiUrl.url}v1/suryaharapan/hutang/addnew`,dataPembelian,{
        headers:{
            'Authorization':`Bearer ${token}`
        }
    })
    .then(res=>{
         axios.post(`${apiUrl.url}v1/suryaharapan/hutang/penyesuaian`,dataPenyesuian,{
            headers:{
                'Authorization':`Bearer ${token}`
            }
         })
         .then(resPenyesuaian=>{
            const ResponseAPIPenyesuaian = resPenyesuaian.data;
            dispatch({
                type:'SET SUCCESSED ACTION HUTANG', 
                payload:ResponseAPIPenyesuaian.message
             })
            setOpenSnackbar(true);
            formik.handleReset();
            handleClickBayar();
         })
         .catch(error=>{
            if(error.response){
                if(error.response.status === 500){
                    dispatch({
                        type:'FAILED ACTION HUTANG',
                        payload:error.response.data.message
                    })
                }else if(error.response.status === 401){
                    dispatch({
                        type:'FAILED ACTION HUTANG',
                        payload:error.response.data.message
                    })
                }
            }else if(error.request){
                     dispatch({
                        type:'FAILED ACTION HUTANG',
                        payload:'Disconnect from server'
                })
            }
        })
    })
    .catch(error=>{
        if(error.response){
            if(error.response.status === 500){
                dispatch({
                    type:'FAILED ACTION HUTANG',
                    payload:error.response.data.message
                })
            }else if(error.response.status === 401){
                dispatch({
                    type:'FAILED ACTION HUTANG',
                    payload:error.response.data.message
                })
            }
        }else if(error.request){
                 dispatch({
                    type:'FAILED ACTION HUTANG',
                    payload:'Disconnect from server'
            })
        }
    })
}

export const getIdHutang = (token) =>(dispatch)=>{
    dispatch({
        type:'FETCH ACTION HUTANG'
    })
    const data={
        'tanggal':new Date()
    }
    axios.post(`${apiUrl.url}v1/suryaharapan/hutang/newidhutang`,[data],{
        headers:{
            'Authorization':`Bearer ${token}`
        }
    })
    .then(res=>{
        const ResponseAPI = res.data;
        dispatch({
            type:'SET DATA ID HUTANG',
            payload:ResponseAPI.data
        })
    })
    .catch(error=>{
        if(error.response){
            if(error.response.status === 500){
                dispatch({
                    type:'FAILED ACTION HUTANG',
                    payload:error.response.data.message
                })
            }else if(error.response.status === 401){
                dispatch({
                    type:'FAILED ACTION HUTANG',
                    payload:error.response.data.message
                })
            }
        }else if(error.request){
                 dispatch({
                    type:'FAILED ACTION HUTANG',
                    payload:'Disconnect from server'
            })
        }
    })
}

export const editStatusNotaTransaksi = (data,token,setOpenSnackbar,HandleClickCloseDeleteDialog) =>(dispatch)=>{
    dispatch({
        type:'FETCH ACTION HUTANG'
    })
    axios.post(`${apiUrl.url}v1/suryaharapan/hutang/updatestatusnota`,data,{
        headers:{
            'Authorization':`Bearer ${token}`
        }
    })
    .then(res=>{
        const ResponseAPI=res.data;
        dispatch({
            type:'SET SUCCESSED ACTION HUTANG', 
            payload:ResponseAPI.message
         })
         setOpenSnackbar(true);
         HandleClickCloseDeleteDialog();
    })
    .catch(error=>{
        if(error.response){
            if(error.response.status === 500){
                dispatch({
                    type:'FAILED ACTION HUTANG',
                    payload:error.response.data.message
                })
            }else if(error.response.status === 401){
                dispatch({
                    type:'FAILED ACTION HUTANG',
                    payload:error.response.data.message
                })
            }
        }else if(error.request){
                 dispatch({
                    type:'FAILED ACTION HUTANG',
                    payload:'Disconnect from server'
            })
        }
    })
}
export const deleteHutangByTransaksi = (token,idtransaksi,setOpenSnackbar,HandleClickCloseDeleteDialog)=>(dispatch) =>{
    dispatch({
        type:'FETCH ACTION HUTANG'
    })
    axios.delete(`${apiUrl.url}v1/suryaharapan/hutang/penghapusanbyidtransaksi/${idtransaksi}`,{
        headers:{
            'Authorization':`Bearer ${token}`
        }
    })
    .then(res=>{
        const ResponseAPI=res.data;
        dispatch({
            type:'SUCCESSED DELETE HUTANG', 
            payload:ResponseAPI.message
         })
         setOpenSnackbar(true);
         HandleClickCloseDeleteDialog();
    })
    .catch(error=>{
        if(error.response){
            if(error.response.status === 500){
                dispatch({
                    type:'FAILED ACTION HUTANG',
                    payload:error.response.data.message
                })
            }else if(error.response.status === 401){
                dispatch({
                    type:'FAILED ACTION HUTANG',
                    payload:error.response.data.message
                })
            }
        }else if(error.request){
                 dispatch({
                    type:'FAILED ACTION HUTANG',
                    payload:'Disconnect from server'
            })
        }
    })
}