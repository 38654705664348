import { Button, Divider, IconButton, makeStyles, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip, Typography, withStyles } from '@material-ui/core'
import { NavigateBefore, NavigateNext } from '@material-ui/icons';
import Cookies from 'js-cookie';
import React,{useEffect, useState} from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { PembayaranCombo, Search } from '../../component/molecules'
import { KoreksiStokBarang } from '../../component/organism'
import { addFormOrder, deleteFormOrder, emptyFormOrder, resetIdentifier, setDataStokBarang, setSearchStok } from '../../config/redux/action';
import { InfoHistory, InfoHistoryOld, KoreksiStok } from '../../component/assets';
import AddIcon from '@material-ui/icons/Add';
import { format } from 'date-fns';
import CancelIcon from '@material-ui/icons/Cancel';
import OrderDeleteForm from '../../component/organism/OrderDeleteForm';
import NewOrderForm from '../../component/organism/NewOrderForm';

const StokBarang = () => {
    const useStyle = makeStyles((theme)=>({
        root:{
            width:'100%',
            height:'100%',
            display:'flex',
            flexDirection:'column',
            justifyContent:'center'
        },
        title:{
            textAlign:'center',
            padding:'5px'
        },
        table:{
            marginLeft:'2%',
            marginTop:'10px',
            maxWidth:'95%',
        },
        tableContainer:{
            display:'flex',
            flexDirection:'column',
            maxHeight:'520px',
            [theme.breakpoints.up('md')]:{
                height:'420px',
            },
        },page:{
            display:'flex',
            width:'100%',
            margin:'0px',
        },
        textPage:{
            padding:'5px',
        },
        buttonHistory:{
            position: 'fixed',
            bottom: theme.spacing(2),
            right: theme.spacing(2),
        },
        headerContent:{
            width:'95%',
            display:'flex',
            flexDirection:'row'
        }
    }))
    const dataSales =[
        {id:5,tipe:'5 Hari'},
        {id:10,tipe:'10 Hari'},
        {id:15,tipe:'15 Hari'},
        {id:20,tipe:'20 Hari'},
        {id:30,tipe:'30 Hari'},
        {id:45,tipe:'45 Hari'},
        {id:60,tipe:'60 Hari'}
      ]
    const history = useHistory();
    const SytledTableCell = withStyles((theme)=>({
        head: {
            backgroundColor: 'grey',
            color: theme.palette.common.black,
          },
          body: {
            fontSize: 14,
            // border:'1px solid black'
          },
    }))(TableCell);
    const StyledTableRow = withStyles((theme) => ({
        root: {
          '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.action.hover,
          },
        },
      }))(TableRow);
    const classes = useStyle();
    const [tempId,setTempId] = useState("");
    const [tempNamaBrg,setTempNamaBrg] = useState("");
    const [openKoreksi,setOpenKoreksi] = useState(false);
    const [showKoreksi,setShowKoresi] = useState(false);
    const [countClick,setCountClick] = useState(0);
    const [salesPeriod,setSalesPeriod]=useState(20);
    const {dataStokBarang,page,search} = useSelector(state => state.stokbarangReducers);
    const {data,identifier} = useSelector(state => state.loginReducers);
    const [counter,setCounter] = useState(1);
    const dispatch = useDispatch();
    const [token,setToken] = useState(data.token);
    const [openDelete,setOpenDelete]=useState(false);
    const [openNewOrder,setOpenNewOrder]=useState(false);
    const [level,setLevel]=useState(data.level);
    const [username,setUsername]=useState(data.username);
    if(token ===''){
        setToken(Cookies.get('u_tkn'));
    }
    if(level===''){
        setLevel(Cookies.get('level'));
    }
    if(username===''){
        setUsername(Cookies.get('username'));
    }
    useEffect(()=>{
        dispatch(setDataStokBarang(search,salesPeriod,counter,token));
        if(identifier===null){
            const data={
                'username':username,
                'width':window.outerWidth,
                'cookieIdentifier':Cookies.get('hexiden')||"021"
            }
            dispatch(resetIdentifier(data,token));
        }
    },[search,salesPeriod,counter,token,dispatch,identifier,username])
    const handlePrev = () =>{
        setCounter(counter <= 1? 1 : counter - 1);
    }
    const handleNext = () =>{
        setCounter(counter === page.totalPage ? page.totalPage : counter + 1);
    }
    const focus = () =>{
        setCounter(1);
        dispatch(setDataStokBarang(search,salesPeriod,counter,token));
    }
    const handleOpenKoreksiStok = (idbarang,namabarang) =>{
        setOpenKoreksi(true);
        setTempId(idbarang);
        setTempNamaBrg(namabarang);
    }
    const handleCloseKoreksiStok = () =>{
        setOpenKoreksi(false);
        dispatch(setDataStokBarang(search,salesPeriod,counter,token));
    }
    const handleClickText = () =>{
        setCountClick(countClick + 1);
        if(countClick === 5){
            setShowKoresi(!showKoreksi);
            setCountClick(0);
        }
    }
    const handleChangePeriod = (e) =>{
        setSalesPeriod(e.target.value);
        dispatch(setDataStokBarang(search,e.target.value,counter,token));
    }
    const handleSearchStokBarang = (e) =>{
        dispatch(setSearchStok(e.target.value));
    }
    const HandleOpenDeleteOrder = (idbarang,namabarang,tglorder,qtyorder) =>{
        dispatch(deleteFormOrder(idbarang,namabarang,qtyorder,format(new Date(tglorder),'dd/MM/yyyy')));
        setOpenDelete(true);
        dispatch(setDataStokBarang(search,salesPeriod,counter,token));
      }
      const HandleOpenAddOrder = (idbarang,namabarang,qtysales,salesPeriod,forcastOrder,forcast90,stokbarang) =>{
        dispatch(addFormOrder(idbarang,namabarang,salesPeriod,qtysales,forcastOrder,forcast90,stokbarang));
        setOpenNewOrder(true);
        dispatch(setDataStokBarang(search,salesPeriod,counter,token));
      }
      const HandleCloseDeleteOrder = () =>{
        dispatch(emptyFormOrder());
        setOpenDelete(false);
        dispatch(setDataStokBarang(search,salesPeriod,counter,token));
        }
    const handleCloseNewOrder = () =>{
        setOpenNewOrder(false);
        dispatch(emptyFormOrder());
        dispatch(setDataStokBarang(search,salesPeriod,counter,token));
        }
    const viewFullFitur = (
        <TableContainer className={classes.tableContainer}>
            <Table stickyHeader aria-label="table barang" size="small">
                <TableHead>
                    <TableRow>
                        <SytledTableCell width='10%' align="center">Sales H-{salesPeriod}</SytledTableCell>
                        <SytledTableCell width='10%' align="center">Forecast Order</SytledTableCell>
                        <SytledTableCell width='10%' align="center">AVG(30 Hari)</SytledTableCell>
                        <SytledTableCell width='10%' align="center">Harga 2</SytledTableCell>
                        <SytledTableCell width='25%' align="center">Nama Barang</SytledTableCell>
                        <SytledTableCell width='10%' align="center">Harga Jual</SytledTableCell>
                        <SytledTableCell width='10%' align="center">Total Stok</SytledTableCell>
                        <SytledTableCell width='10%' align="center">PO Order</SytledTableCell>
                        <SytledTableCell width='10%' align="center">Stok Toko</SytledTableCell>
                        <SytledTableCell width='10%' align="center">Stok Gudang</SytledTableCell>
                        <SytledTableCell width='10%' align="center">Stok Retur</SytledTableCell>
                        <SytledTableCell width='10%' align="center">Jenis Barang</SytledTableCell>
                        <SytledTableCell width='10%' align="center">Satuan</SytledTableCell>
                        <SytledTableCell width='10%' align="center">Order</SytledTableCell>
                        {showKoreksi === true? <SytledTableCell width='3%' align="center"></SytledTableCell> : null}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {dataStokBarang.map((dataStokBarangs=>(
                        <StyledTableRow key={dataStokBarangs.idbarang} style={{border:'1px solid black'}}>
                            <SytledTableCell align="center">{dataStokBarangs.qtysales}</SytledTableCell>
                            <SytledTableCell align="center">{dataStokBarangs.forcastOrder}</SytledTableCell>
                            <SytledTableCell align="center">{dataStokBarangs.forcast90}</SytledTableCell>
                            <SytledTableCell align="center">{new Intl.NumberFormat().format(dataStokBarangs.hargajual2)}</SytledTableCell>
                            <SytledTableCell align="center">{dataStokBarangs.namabarang} {
                                dataStokBarangs.keterangan !== "-" && dataStokBarangs.keteranganOld==="-"? 
                                <Tooltip title={dataStokBarangs.keterangan}>
                                <img src={InfoHistory} style={{width:'15px',height:'15px'}} alt="info"/> 
                                </Tooltip>
                                :
                                dataStokBarangs.keterangan === "-" && dataStokBarangs.keteranganOld !== "" ?
                                <Tooltip title={dataStokBarangs.keteranganOld}>
                                <img src={InfoHistoryOld} style={{width:'15px',height:'15px'}} alt="infoOld"/> 
                                </Tooltip> : null
                            }</SytledTableCell>
                            <SytledTableCell align="center">{
                                new Intl.NumberFormat().format(dataStokBarangs.hargajual)
                            }</SytledTableCell>
                            <SytledTableCell align="center"><b>{dataStokBarangs.sisastok}</b></SytledTableCell>
                            <SytledTableCell align="center"><b>{dataStokBarangs.poorder}</b></SytledTableCell>
                            <SytledTableCell align="center"><b>{dataStokBarangs.stoktoko}</b></SytledTableCell>
                            <SytledTableCell align="center"><b>{dataStokBarangs.stokgudang}</b></SytledTableCell>
                            <SytledTableCell align="center"><b>{dataStokBarangs.stokretur}</b></SytledTableCell>
                            <SytledTableCell align="center">{dataStokBarangs.jenisbarang}</SytledTableCell>
                            <SytledTableCell align="center">{dataStokBarangs.satuan}</SytledTableCell>
                            {showKoreksi === true? 
                                <SytledTableCell>
                                    <Tooltip title="Koreksi stok">
                                        <IconButton size='small' 
                                        onClick={()=>handleOpenKoreksiStok(dataStokBarangs.idbarang,dataStokBarangs.namabarang)}
                                        >
                                            <img src={KoreksiStok} alt="koreksi"/>
                                        </IconButton>
                                    </Tooltip>
                                </SytledTableCell>
                            :null}
                            {dataStokBarangs.qtyorder>0?
                            <SytledTableCell width='10%' align="center">
                            <IconButton size='small' disabled={true} color="primary">
                                <AddIcon fontSize='small'/>
                            </IconButton>
                            <Tooltip title="Hapus Order">
                                <IconButton size='small' color="secondary"
                                onClick={()=>HandleOpenDeleteOrder(dataStokBarangs.idbarang,dataStokBarangs.namabarang,dataStokBarangs.tglorder,dataStokBarangs.qtyorder)}
                                >
                                    <CancelIcon fontSize='small'/>
                                </IconButton>
                            </Tooltip>
                            </SytledTableCell>:
                            <SytledTableCell width='10%' align="center">
                            <Tooltip title="Tambah Order">
                                <IconButton size='small' color="primary"
                                    onClick={()=>HandleOpenAddOrder(dataStokBarangs.idbarang,dataStokBarangs.namabarang,
                                        dataStokBarangs.qtysales,salesPeriod,dataStokBarangs.forcastOrder,
                                        dataStokBarangs.forcast90,parseInt(dataStokBarangs.stoktoko)+parseInt(dataStokBarangs.stokgudang))}
                                >
                                    <AddIcon fontSize='small'/>
                                </IconButton>
                                </Tooltip>
                                <IconButton size='small' disabled={true} color="secondary">
                                    <CancelIcon fontSize='small'/>
                                </IconButton>
                            </SytledTableCell>}
                        </StyledTableRow>
                    )))}
                </TableBody>
            </Table>
        </TableContainer>
    )
    
    const viewKasirFitur = (
        <TableContainer className={classes.tableContainer}>
            <Table stickyHeader aria-label="table barang" size="small">
                <TableHead>
                    <TableRow>
                        <SytledTableCell width='25%' align="center">Nama Barang</SytledTableCell>
                        {identifier===true?<SytledTableCell width='10%' align="center">Harga Jual</SytledTableCell>:null}
                        <SytledTableCell width='10%' align="center">Total Stok</SytledTableCell>
                        <SytledTableCell width='10%' align="center">PO Order</SytledTableCell>
                        <SytledTableCell width='10%' align="center">Stok Toko</SytledTableCell>
                        <SytledTableCell width='10%' align="center">Stok Gudang</SytledTableCell>
                        <SytledTableCell width='10%' align="center">Stok Retur</SytledTableCell>
                        <SytledTableCell width='10%' align="center">Jenis Barang</SytledTableCell>
                        <SytledTableCell width='10%' align="center">Satuan</SytledTableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {dataStokBarang.map((dataStokBarangs=>(
                        <StyledTableRow key={dataStokBarangs.idbarang} style={{border:'1px solid black'}}>
                            <SytledTableCell align="center">{dataStokBarangs.namabarang} {
                                dataStokBarangs.keterangan !== "-" ? 
                                <Tooltip title={dataStokBarangs.keterangan}>
                                <img src={InfoHistory} style={{width:'15px',height:'15px'}} alt="info"/> 
                                </Tooltip>
                                :
                                null
                            }</SytledTableCell>
                            {identifier===true?<SytledTableCell align="center">{
                                new Intl.NumberFormat().format(dataStokBarangs.hargajual)
                            }</SytledTableCell>:null}
                            <SytledTableCell align="center"><b>{dataStokBarangs.sisastok}</b></SytledTableCell>
                            <SytledTableCell align="center"><b>{dataStokBarangs.poorder}</b></SytledTableCell>
                            <SytledTableCell align="center"><b>{dataStokBarangs.stoktoko}</b></SytledTableCell>
                            <SytledTableCell align="center"><b>{dataStokBarangs.stokgudang}</b></SytledTableCell>
                            <SytledTableCell align="center"><b>{dataStokBarangs.stokretur}</b></SytledTableCell>
                            <SytledTableCell align="center">{dataStokBarangs.jenisbarang}</SytledTableCell>
                            <SytledTableCell align="center">{dataStokBarangs.satuan}</SytledTableCell>
                        </StyledTableRow>
                    )))}
                </TableBody>
            </Table>
        </TableContainer>
    )
    
    return (
        <div className={classes.root}>
                <Typography onClick={handleClickText} className={classes.title} variant="h5">Stok Barang</Typography>
                <Divider/>
                <div style={{width:'100%',display:'flex',justifyContent:'center'}}>
                    <div className={classes.headerContent}>
                        {level==="Pemilik"?
                        <PembayaranCombo
                        id="sales"
                        name="Data Penjualan"
                        data={dataSales}
                        label="Data Penjualan"
                        value={salesPeriod}
                        handleChange={(e)=>{handleChangePeriod(e)}}
                        />:null}
                        <Search label="Cari Barang" name="cari-barang" id="cari-barang" onFocus={focus} onChange={(e)=>handleSearchStokBarang(e)}/>
                    </div>
                </div>
                <div className={classes.table}>
                <Paper>
                    {level==="Pemilik"?viewFullFitur:viewKasirFitur}
                <div className={classes.page}>
                    <Tooltip title="Prev">
                        <IconButton size='small' onClick={handlePrev}>
                            <NavigateBefore/>
                        </IconButton>
                    </Tooltip>
                    <Typography className={classes.textPage}>{page.currentPage} / {page.totalPage >= 1 ? page.totalPage : page.currentPage}</Typography>
                    <Tooltip title="Next">
                        <IconButton size='small' onClick={handleNext}>
                            <NavigateNext/>
                        </IconButton>
                    </Tooltip> 
                </div>
                </Paper>
                </div>
                <Button 
                className={classes.buttonHistory} 
                size="small" 
                variant="contained" 
                color="primary" 
                aria-label="add"
                onClick={()=>{history.push('/filterstok')}}
                disabled={level==="Pemilik" ? false:true}>
                    Print Stok
                </Button>
                <KoreksiStokBarang OpenKoreksi={openKoreksi} 
                handleCloseKoreksi={handleCloseKoreksiStok} 
                tempIdBarang={tempId}
                tempNamaBarang={tempNamaBrg}/>
                <OrderDeleteForm open={openDelete} aria-labelledby="delete-barang-dialog" HandleClickCloseDeleteDialog={HandleCloseDeleteOrder}/>
                <NewOrderForm open={openNewOrder} aria-labelledby="new-order-barang-dialog" onCloseDialogNewOrder = {handleCloseNewOrder}/>
            </div>
    )
}
export default StokBarang
